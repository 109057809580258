import React from 'react';
import axios from 'axios';

function Download() {

    const server_app_api_php = window.SERVER_APP_API_PHP

    const handleDownload = async () => {
        let fileName = document.getElementById("download").value;
        fileName = fileName+".pdf"
        const formData = new FormData();
        formData.append('apiType', 'downloadFile');
        formData.append('file', fileName);

        try {
            const response = await axios.post({server_app_api_php}+'/download.php', formData, {
                responseType: 'blob',
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            const blob = new Blob([response.data], { type: 'application/octet-stream' });
            const downloadLink = document.createElement('a');
            downloadLink.href = window.URL.createObjectURL(blob);
            downloadLink.download = fileName;
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
        } catch (error) {
            console.error('Error downloading file:', error);
        }
    }

    return (
        <div className="container">

            
<div className="row mb-5">
  <div className="col-12 ">
  
      <nav className="navbar navbar-expand-lg navbar-dark blue fixed-top scrolling-navbar col-12">
        <div className="container">
          <a className="navbar-brand" href="."><strong>Wedding For Tabo And Henry</strong></a>
          <button className="navbar-toggler blue" type="button" data-toggle="collapse" data-target="#navbarSupportedContent-7"
            aria-controls="navbarSupportedContent-7" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent-7">
            <ul className="navbar-nav mr-auto">
              <li className="nav-item">
                <a className="nav-link"  href="." >Home</a>
              </li>
              <li className="nav-item">
            <a className="nav-link "  href="Register" >Register</a>
          </li>
          <li className="nav-item">
            <a className="nav-link "  href="RSVP" >RSVP</a>
          </li>
            </ul>
          </div>
        </div>
      </nav>

  </div>


</div>

<hr className='mb-5 mt-5'/>
            
            <div className="md-form md-outline">
          <label htmlFor="Ref">Reference</label>
          <input type="text" className="form-control" id="download" />
          </div>
            <button class='btn btn green white-text' id="downloadButton" onClick={handleDownload}>Download Wedding Card
            <span className='fa fa-download ml-4'></span>
            </button>
        </div>
    );
}

export default Download;
